import React, { useContext, useState } from "react";
import {
  getFormattedPrice,
  iconBag,
  iconEye,
  iconHeartEmpty,
} from "../../../util/ui_util";
import { ItemHomeProductButton } from "../items/ItemHomeProductButton";
import { Link, useNavigate } from "react-router-dom";
import { AppContext } from "../../../context/app/AppState";
import { useTranslation } from "react-i18next";
import useCart from "../../../hooks/useCart";
import { useSnackbar } from "../../../context/general/SnackbarContext";
import { getDiscountedPrice } from "../../../util/util";
import { HomeContext } from "../../../context/home/HomeState";

export const ItemHomeProduct = ({ product, onHoverChange }) => {
  const [isHovered, setIsHovered] = useState(false);
  const { t } = useTranslation();
  const { language, setShowProductQuickViewDialog } = useContext(AppContext);
  const isRtl = language === "ar";
  const { addProductToCart, getProductCartQuantity } = useCart();
  const { showSuccess, showError } = useSnackbar();
  const { addToWishlistProduct, addProductToWishlist } =
    useContext(HomeContext);
  const navigate = useNavigate();

  const discountedPrice = getDiscountedPrice(product);
  const hasDiscount = product.discount !== 0;

  const handleViewClick = async (e) => {
    e.preventDefault();
    setShowProductQuickViewDialog({
      show: true,
      product,
    });
  };

  const handleAddToCartClick = (e) => {
    e.preventDefault();
    if (product.choice_options.length > 0) {
      navigate(`/product/${product.id}`);
      return;
    }
    const cartQuantity = getProductCartQuantity(product.id);
    if (cartQuantity >= product.total_stock) {
      showError(t("productMaxQuantityMessage"));
      return;
    }
    addProductToCart(product, 1);
    showSuccess(t("productAddedToCartMessage"));
  };

  const handleAddToWishlistClick = (e) => {
    e.preventDefault();
    addProductToWishlist({ productId: product.id });
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
    onHoverChange(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    onHoverChange(false);
  };

  return (
    <Link to={`/product/${product.id}`} draggable={false}>
      <div
        className={`flex-none flex-col w-[144px] lg:w-[300px] items-center cursor-pointer`}
      >
        <div
          className="relative w-full overflow-hidden hidden lg:block h-[390px]"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <img
            src={product.image_fullpath[0]}
            alt=""
            className={`w-full h-[144px] lg:h-[300px] object-cover transition-transform duration-300 ${
              isHovered ? "scale-110" : "scale-100"
            }`}
            draggable={false}
          />
          <div
            className={`flex flex-col absolute bottom-0 left-0 right-0 bg-white py-2 space-y-2 items-center transition-all duration-300 ${
              isHovered ? "h-[140px]" : "h-[90px]"
            }`}
          >
            <div className="font-bold  text-text">{product.name}</div>
            <div className="flex flex-col items-center">
              <div className="flex flex-row items-center justify-center space-x-2 rtl:space-x-reverse">
                <div
                  className={`${hasDiscount ? "text-red-500" : "text-black"} ${
                    hasDiscount ? "font-bold" : ""
                  } text-sm`}
                  dir={isRtl ? "rtl" : "ltr"}
                >
                  {getFormattedPrice(discountedPrice, language)}
                </div>
                <div
                  className={`${hasDiscount ? "block" : "hidden"} ${
                    hasDiscount ? "line-through" : ""
                  } text-sm`}
                  dir={isRtl ? "rtl" : "ltr"}
                >
                  {getFormattedPrice(product.price, language)}
                </div>
              </div>
              {/* <div
                className={`${
                  hasDiscount ? "block" : "hidden"
                } text-red-500 text-sm font-bold`}
              >
                {getDiscountString(product, t, language)}
              </div> */}
            </div>
            {isHovered && (
              <div className="flex flex-row items-center space-x-2 rtl:space-x-reverse mt-4">
                <ItemHomeProductButton
                  icon={iconHeartEmpty({
                    fillColor: "#000000",
                    width: "1.5rem",
                    height: "1.5rem",
                  })}
                  iconFilled={iconHeartEmpty({
                    fillColor: "#ffffff",
                    width: "1.5rem",
                    height: "1.5rem",
                  })}
                  color={"#000000"}
                  onClick={handleAddToWishlistClick}
                  isLoading={addToWishlistProduct.loading}
                />
                <ItemHomeProductButton
                  icon={iconEye({
                    fillColor: "#000000",
                    width: "1.5rem",
                    height: "1.5rem",
                  })}
                  iconFilled={iconEye({
                    fillColor: "#ffffff",
                    width: "1.5rem",
                    height: "1.5rem",
                  })}
                  color={"#000000"}
                  onClick={handleViewClick}
                />
                <ItemHomeProductButton
                  icon={iconBag({
                    fillColor: "#000000",
                    width: "1.5rem",
                    height: "1.5rem",
                  })}
                  iconFilled={iconBag({
                    fillColor: "#ffffff",
                    width: "1.5rem",
                    height: "1.5rem",
                  })}
                  color={"#000000"}
                  onClick={handleAddToCartClick}
                />
              </div>
            )}
          </div>
        </div>
        {/* md layout */}
        <div className="relative flex flex-col w-full h-[350px] lg:hidden">
          <img
            src={product.image_fullpath[0]}
            alt=""
            className={`w-full h-[272px] object-contain`}
            draggable={false}
          />
          <div
            className={`flex flex-col bottom-0 left-0 right-0 bg-white p-2 justify-between items-center h-[160px]`}
          >
            <div className="flex flex-col items-center">
              <div className="font-bold text-text text-sm text-center">
                {product.name}
              </div>
              <div className="flex flex-row items-center justify-center space-x-2 rtl:space-x-reverse">
                <div
                  className={`${hasDiscount ? "text-red-500" : "text-black"} ${
                    hasDiscount ? "font-bold" : ""
                  } text-sm`}
                  dir={isRtl ? "rtl" : "ltr"}
                >
                  {getFormattedPrice(discountedPrice, language)}
                </div>
                <div
                  className={`${hasDiscount ? "block" : "hidden"} ${
                    hasDiscount ? "line-through" : ""
                  } text-sm`}
                  dir={isRtl ? "rtl" : "ltr"}
                >
                  {getFormattedPrice(product.price, language)}
                </div>
              </div>
              {/* <div
                className={`${
                  hasDiscount ? "block" : "hidden"
                } text-red-500 text-sm font-bold`}
              >
                {getDiscountString(product, t, language)}
              </div> */}
            </div>
            <div className="flex flex-row items-center space-x-2 rtl:space-x-reverse mt-4">
              <ItemHomeProductButton
                icon={iconHeartEmpty({
                  fillColor: "#000000",
                  width: "1.5rem",
                  height: "1.5rem",
                })}
                iconFilled={iconHeartEmpty({
                  fillColor: "#ffffff",
                  width: "1.5rem",
                  height: "1.5rem",
                })}
                color={"#000000"}
                onClick={handleAddToWishlistClick}
                isLoading={addToWishlistProduct.loading}
              />
              <ItemHomeProductButton
                icon={iconBag({
                  fillColor: "#000000",
                  width: "1.5rem",
                  height: "1.5rem",
                })}
                iconFilled={iconBag({
                  fillColor: "#ffffff",
                  width: "1.5rem",
                  height: "1.5rem",
                })}
                color={"#000000"}
                onClick={handleAddToCartClick}
              />
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};
